import React, { Component} from "react";
import AuthContext from "../../components/Auth/Auth-context";
import { Dropdown } from "react-bootstrap";
import { Trans } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./Nav.scss";
import Fuse from "fuse.js";
//import config from "../../config";
import axios from "axios";
let searchValue = "";
//let userMail = "";
// let userFirstName = "";
// let userLastName = "";
let products = {};
let productOptions = {
  includeMatches: true,
  minMatchCharLength: 3,
  keys: ["attributeName"],
  threshold: 0.3,
};
let removingRepeatedAttr = []
window.dataLayer = window.dataLayer || [];
const suggestionWrapper = React.createRef();

const gigya = window.gigya;
class Navbar extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      fusePattern: "",
      suggestion: [],
    };
  }
  
  toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("close");
  }
  async componentDidMount() {
    const response = await axios.get(
      // `${config.favApi}dc/datacatalog/allDataForDataCatalog`,
      `${process.env.REACT_APP_favApi}dc/datacatalog/allAttributesForDataCatalog`,
      {
        // headers: {
        //   "X-Country-Code": `${getCountryCode()}`
        // },
        params: { date: Date.now() }
      }
    );

    products = response.data.data;
    this.profile();
    // console.log("products", products);
     // eslint-disable-next-line no-unused-expressions
    this.handleChange
  }
  async componentDidUpdate() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  handleChange = async (event) => {
    searchValue = event.target?.value;
    // console.log("productseven", event, "even", searchValue);
    await this.setState({ fusePattern: "" });
    // console.log("products65", this.state?.fusePattern, "651", searchValue);
    await this.setState({ searchKey: searchValue });
    // console.log("products67", this.state?.searchKey, "671", searchValue);
    if (searchValue.length > 2) {
      this.setState({ fusePattern: searchValue });
      
      const productFuse = products && new Fuse(products, productOptions);
      const productResult = productFuse.search(searchValue);
      const productArray = [];
      productResult.forEach(function (productItem) {
        productArray.push({
          value: productItem.item.attributeName,
        });
      });
      await this.setState({ suggestion: productArray });
      removingRepeatedAttr = this.state.suggestion.filter( (ele, ind) => ind === this.state.suggestion.findIndex( elem => elem.value === ele.value ))
    }
  };

  onKeyDown = (event) => {
    // console.log("searchAttributekey", event, searchValue, this.state.searchKey);
    if (event?.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      searchValue = event.target.value;
      this.setState({ searchKey: searchValue });
      // this.props.history.push({
      //   pathname: `/search-page/?searchKey/${this.state.searchKey}`,
      // });
    }
  };

  handleClickOutside = (event) => {
    if (
      suggestionWrapper.current &&
      !suggestionWrapper.current.contains(event.target)
    ) {
      this.setState({ fusePattern: "" });
    }
  };

  handleUserClick(item) {
    this.setState({ fusePattern: "" });
    this.setState({ searchKey: item.value });
    this.props.history.push({
      pathname: `/search-page/?searchKey/${item.value}`,
    });
  }

  getItems = this.state?.suggestion.map((item, index) => {
    return (
      <li
        value={item.attributeName}
        key={item.key}
      >
        {item.attributeName}
      </li>
    );
  });
  GetDropDown = () => {
    if (this.state?.suggestion.length) {
      return (
        <div className="suggestion_wrapper" ref={suggestionWrapper}>
          <ul className="suggestions">{this.getItems}</ul>
        </div>
      );
    } else {
      return (
        <div className="no-suggestions">
          <em>No suggestions available.</em>
        </div>
      );
    }
  };

  feedbackPrompt = () => {
    this.props.history.push("/LoginPage");
  };
  logOutOnClick = () => {
    // console.log("hellohi", AuthContext, "use", "gigya", gigya);
    const AuthCtx = this.context.logout();

    gigya.accounts.logout({
      callback: function (response) {
        // console.log("hellohi223", response);
        if (response.errorCode === 0) {
          // eslint-disable-next-line no-unused-expressions
          AuthCtx;
          // eslint-disable-next-line no-unused-expressions
          this.feedbackPrompt;
        }
      },
    });
  };
  profile = () => {
    let profileData = localStorage.getItem("userProfile");
    let userProfile =
      profileData !== undefined && profileData !== null
        ? JSON.parse(profileData)
        : "";
    //userMail = userProfile.email;
    // userFirstName = userProfile.firstName;
    // userLastName = userProfile.lastName;
    return ` ${userProfile?.firstName} ${userProfile?.lastName} `;
  };

  render() {
    return (
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/">
            <img
              src={require("../../assets/images/downloadCenterLogo.png")}
              alt="logo"
            />
          </Link>
          <Link className="navbar-brand brand-logo-mini" to="/">
            <img 
            src={require('../../assets/images/SmallLogo.png')} style={{width:"65px",height:"50px", marginLeft:"35%"}} alt="logo" />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <span className="mdi mdi-menu"></span>
          </button>
          <div className="searchBox w-100 mr-5" >
            <div className="search-field d-none d-md-block w-100">
              <form className="d-flex align-items-center h-100 ">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control bg-transparent border-0"
                    placeholder="Search Attributes..."
                    value={this.state.searchKey}
                    onChange={this.handleChange}
                    onKeyDown={(e) => this.onKeyDown(e)}
                  />
                  {searchValue ? (
                    <Link
                      to={{
                        pathname: `/search-page/?searchKey/${this.state.searchKey}`,
                      }}
                      style={{ color: "#fff", textDecoration: "auto" }}
                    >
                      <div className="input-group-prepend bg-transparent search-icon">
                        <i className="input-group-text border-0 mdi mdi-magnify"></i>
                      </div>
                    </Link>
                  ) : (
                    // <Link
                    //   to={{ pathname: `/search-page/?searchKey/` }}
                    //   style={{ color: "#fff", textDecoration: "auto" }}
                    // >
                      <div className="input-group-prepend bg-transparent search-icon">
                        <i className="input-group-text border-0 mdi mdi-magnify"></i>
                      </div>
                    // </Link>
                  )}
                </div>
              </form>
            </div>
           
            {
              this.state.fusePattern && this.state?.suggestion.length ? (
                <div
                  className="suggestion_wrapper w-100 mt-1"
                  ref={suggestionWrapper}
                >
                  <ul className="suggestions">
                    {removingRepeatedAttr.map((item, index) => {
                      return (
                        <li
                          value={item.attributeName}
                          key={item.key}
                          onClick={this.handleUserClick.bind(this, item)}
                        >
                          {item.value}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <div className="no-suggestions">
                  <em>No suggestions available.</em>
                </div>
              )
            }
          </div>
          <ul className="navbar-nav navbar-nav-left">
            <ul className="navbar-nav navbar-nav-center">
              <li className="nav-item nav-profile">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    {" "}
                    <AccountCircleIcon
                      aria-hidden="true"
                      style={{ fontSize: "1.5rem" }}
                    />
                    {JSON.parse(localStorage.getItem('userProfile'))?.firstName && JSON.parse(localStorage.getItem('userProfile'))?.lastName && 
                    (JSON.parse(localStorage.getItem('userProfile'))?.firstName !== undefined 
                    && JSON.parse(localStorage.getItem('userProfile'))?.lastName !== undefined )
                     ? JSON.parse(localStorage.getItem('userProfile'))?.firstName +" " + JSON.parse(localStorage.getItem('userProfile'))?.lastName
                      : localStorage.getItem("email")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="navbar-dropdown profile-dropdown">
                    <Dropdown.Item
                      // href="!#"
                      onClick={(evt) => evt.preventDefault()}
                      style={{ height: "35px" }}
                    >
                      <Trans>{localStorage.getItem("email")}</Trans>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={this.logOutOnClick}
                      style={{ height: "35px" }}
                    >
                      <i className="mdi mdi-logout mr-2 text-primary"></i>
                      <Trans>LogOut</Trans>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
            <li className="nav-item nav-logo-right">
              <Dropdown alignRight>
                <div className="nav-logo">
                  <img alt="" src={require("../../assets/images/faces/face0.png")} />
                </div>
              </Dropdown>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={this.toggleOffcanvas}
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    );
  }
}
export default withRouter(Navbar);
