import React, { Suspense, lazy, useContext, useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import  { store, persistor } from "../src/redux/store";
import Spinner from './pages/shared/Spinner';
import AuthContext from "./components/Auth/Auth-context";
import axios from 'axios';
// import config from "./config.js";
import { Helmet } from "react-helmet";

const Login = lazy(() => import('./pages/LoginPage/LoginPage'));
const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const GlobalAttributes = lazy(() => import('./pages/GlobalAttributes/GlobalAttributes'));
const Administration = lazy(() => import('./pages/Administration/Administration'))
const RetailerAttributes = lazy(() => import('./pages/RetailerAttributes/RetailerAttributes'))
const RegionAttributes = lazy(() => import('./pages/RegionAttributes/RegionAttributes'))
const SearchPage = lazy(() => import('./pages/SearchPage/SearchPage'))
const Lov = lazy(() => import('./pages/Lov/Lov'))
const DataUpdateLogs = lazy(() => import('./pages/AuditLogs/DataUpdateLogs'))
const DataRefreshLogs = lazy(() => import('./pages/AuditLogs/DataRefreshLogs'))
const RolePage =lazy(() => import('./pages/RolePage/RolePage'));
const UpdateMetadatas = lazy(() => import('./pages/Settings/UpdateMetadatas'));
const Taxonomy = lazy(() => import('./pages/Taxonomy/Taxonomy'));

function AppRoutes() {
  const AuthCtx = useContext(AuthContext);
  let title = "DataCatalog 2.0 | Bayer";
  //let config = process.env;
  axios.interceptors.request.use(async function (config) {
    // console.log('config-->',config);
    // console.log('process.env-->',process.env);
    try {
      const token = await AuthCtx.getIdToken();
      config.headers.Authorization = "Bearer " + token;
      return config;
    } catch (e) {
      config.headers.Authorization = "Bearer TOKEN_NOT_LOADED";
      return config;
    }

  });
  const getUserSettings = async userId => {
    //const userSettingsApi = `${config.favApi}dc/userdata/${userId}/settings`;
    const userSettingsApi = `${process.env.REACT_APP_favApi}dc/userdata/${userId}/settings`;
    const userSettingsData = await axios.get(userSettingsApi, { params: { date: Date.now() } }).then(res => res.data);
    const settings = userSettingsData.data;
    return settings;
  };
  useEffect(() => {
    async function fetchToken() {
      if (AuthCtx.isLoggedIn) {
        const userId = AuthCtx.giveToken();
        if (userId !== undefined && userId !== null && userId.length > 0) {
          let langSettings = getUserSettings(userId);
          langSettings
            .then(resp => {
              if (resp.settings.hasOwnProperty("defaultLang")) {
              }
            })
            .catch(error => console.log(error));

        }

      }
    }
    fetchToken();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AuthCtx, AuthCtx.isLoggedIn]);


  return (
    <Provider store={store}>
       <PersistGate loading={null} persistor={persistor}>
        <Helmet>
           <title>DataCatalog 2.0 | Bayer </title>
        </Helmet>
        <Suspense fallback={<Spinner />}>
        <Switch>          
          {AuthCtx.isLoggedIn && <Route exact path="/Dashboard"> 
           <Dashboard title="DataCatalog 2.0 | Bayer" />                 
          </Route>}
          {AuthCtx.isLoggedIn && <Route exact path="/GlobalAttributes"> 
           <GlobalAttributes title="DataCatalog 2.0 | Bayer" />                 
          </Route>}
          {AuthCtx.isLoggedIn && <Route exact path="/RetailerAttributes" > 
           <RetailerAttributes title="DataCatalog 2.0 | Bayer" />                 
          </Route>}
          <Route path="/LoginPage" exact>
            {AuthCtx.isLoggedIn && <Redirect to="/Dashboard" />}
            {!AuthCtx.isLoggedIn && (
              <Login title="DataCatalog 2.0 | Bayer " />
            )}
          </Route>
          {/* <Route exact path="/LoginPage" component={ Login } /> */}
          {AuthCtx.isLoggedIn && <Route exact path="/search-page/?searchKey/*"> 
          <SearchPage title="DataCatalog 2.0 | Bayer" />                 
         </Route>}
          {AuthCtx.isLoggedIn && <Route exact path="/Settings/Administration"> 
          <Administration title="DataCatalog 2.0 | Bayer" />                 
         </Route>}
          {AuthCtx.isLoggedIn && <Route exact path="/LOVs" > 
          <Lov title="DataCatalog 2.0 | Bayer" />                 
         </Route>}
          {AuthCtx.isLoggedIn && <Route exact path="/MarketAttributes" > 
          <RegionAttributes title="DataCatalog 2.0 | Bayer" />                 
         </Route>}
          {AuthCtx.isLoggedIn && <Route exact path="/Taxonomy" > 
          <Taxonomy title="DataCatalog 2.0 | Bayer" />                 
         </Route>}
          {AuthCtx.isLoggedIn && <Route exact path="/Settings/DataUpdateLogs" > 
          <DataUpdateLogs title="DataCatalog 2.0 | Bayer" />                 
         </Route>}
          {AuthCtx.isLoggedIn && <Route exact path="/Settings/DataRefreshLogs" > 
          <DataRefreshLogs title="DataCatalog 2.0 | Bayer" />                 
         </Route>}
          {AuthCtx.isLoggedIn && <Route exact path="/Settings/Rolepage" > 
          <RolePage title="DataCatalog 2.0 | Bayer" />                 
         </Route>}
          {AuthCtx.isLoggedIn && <Route exact path="/Settings/UpdateMetadatas" > 
          <UpdateMetadatas title="DataCatalog 2.0 | Bayer" />                 
         </Route>}
          {!AuthCtx.isLoggedIn && <Redirect to="/LoginPage" />}
          {AuthCtx.isLoggedIn && <Redirect to="/Dashboard" />}
        </Switch>
       
      </Suspense>
      </PersistGate>
    </Provider>
  );
}


export default AppRoutes;